import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import IdeetippList from "../components/IdeetippList"
import SEO from "../components/SEO"

const contact = ({
  data: {
    allContentfulIdeetipp: { nodes: ideetipp },
  },
}) => {
  return (
    <Layout>
      <SEO title="Kontakt " />
      <main className="page">
        <section className="contact-page">
          <article className="contact-info">
            <h3>Jetzt kontaktieren!</h3>
            <p>
              Du hast eine gute Idee und willst sie mit mir teilen? Schreib mir!
            </p>
            <p>
              Du hast eine bessere Idee und willst sie mit mir teilen? Schreib
              mir!
            </p>
            <p>Du willst einfach mal sehen, ob ich antworte? Schreib mir!</p>
          </article>
          <article>
            <form
              className="form contact-form"
              action="https://formspree.io/f/xknygkel"
              method="POST"
            >
              <div className="form-row">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="form-row">
                <label htmlFor="email">Emailadresse</label>
                <input type="email" name="email" id="email" />
              </div>
              <div className="form-row">
                <label htmlFor="message">Nachricht</label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <button type="submit" className="btn block">
                Senden
              </button>
            </form>
          </article>
        </section>
        <section className="featured-recipes">
          <h5>Schau dir die tollen Tipps und Ideen an!</h5>
          <IdeetippList ideetipp={ideetipp} />
        </section>
      </main>
    </Layout>
  )
}
export const data = graphql`
  {
    allContentfulIdeetipp(
      sort: { fields: createdAt, order: DESC }
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        id
        title
        createdAt
        subtitle
        durationTime
        prepTime
        image {
          id
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`
export default contact
